import React from 'react'

export const PrevIcon = ({ direction = 'prev' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        css={{ transform: direction === 'next' && 'scale(-1,1)' }}
        viewBox="0 0 25 23.468"
    >
        <path d="M14.725 20.55l-8.887-8.816 8.887-8.816L11.832 0 0 11.734l11.832 11.734z" />
        <path d="M22.105 23.468L25 20.55l-8.887-8.816L25 2.918 22.107 0 10.274 11.734z" />
    </svg>
)

export const NextIcon = () => <PrevIcon direction="next" />
