import React from 'react'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import Link from '../Link/Link'

const Wrapper = styled.div`
    border-radius: 5px;
    text-align: left;
    overflow: hidden;
    position: relative;
    background-color: #fff;
    border: ${props => props.border && '1px solid #dddddd'};
    box-shadow: ${props => props.shadow && '0px 3px 6px #00000029'};
    display: flex;
    flex-direction: column;
`

const Title = styled.h3`
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 0.5em;
`

const Content = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
`

const StrechedLink = styled(Link)`
    text-decoration: none;
    text-transform: uppercase;
    margin-top: 1rem;
    display: inline-block;
    font-weight: 700;
    font-size: 0.9em;
    &:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
    }
`

export default function Card({
    title = 'Card Title',
    children,
    linkHref,
    linkTitle = 'Read More',
    className,
    image,
    border = true,
    shadow = false,
}) {
    return (
        <Wrapper className={className} border={border} shadow={shadow}>
            {image && <Img fluid={image} css={{ maxHeight: 200 }} />}

            <Content>
                <div>
                    <Title dangerouslySetInnerHTML={{ __html: title }} />
                    <p
                        css={{ margin: 0 }}
                        dangerouslySetInnerHTML={{ __html: children }}
                    />
                </div>
                <StrechedLink to={linkHref}>{linkTitle} ›</StrechedLink>
            </Content>
        </Wrapper>
    )
}
